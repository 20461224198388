$type-headline: 'Montserrat', sans-serif;
$type-body: 'Raleway', sans-serif;

body {
  font-family: $type-body;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $type-headline;
  color: $cobras-blue;
}

h1.page-title {
	font-weight: 900;
}
body {
  margin: 0;
}
/*
 * Container
 */

.hero {
  position: relative;
  display: block;

  @include respond(med) {
    min-height: 100vh;
    display: flex;
  }
}
.header {
  margin: 0;
  
  @include respond(med) {
    width: 50vw;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
.link-to-home {
  @include respond(med) {
    margin-bottom: 50px;
    display: block;
  }
}

.main-content {
  width: 100%;
  // margin: 10px auto;
  
  @include respond(med) {
    width: auto;
    max-width: 38em;
  }
}

// hide main contanet on homepage
.homepage .content-wrapper {
  
  @include respond(med) {
    display: none;
  }
}

.hero-right {
  padding: 25px;
  background: url(/img/bg.png) repeat;
  
  @include respond(med) {
    width: 50vw;
    padding-top: 190px;
  }
}
.intro-for-desktop {
  display: none;
  color: #fff;
  font-family: $type-body;
  font-weight: 400;
  margin-top: 50px;
  font-size: 1.3rem;;

  @include respond(med) {
    display: block;
    max-width: 600px;
    line-height: 1.5;
  }
  @include respond(lge) {
    font-size: 1.5rem;
  }

}
.intro-for-mobile {
  display: block;
  color: #000;
  font-family: $type-body;
  font-weight: 400;
  margin: 20px 0 50px;
  font-size: 1.3rem;;

  @include respond(med) {
    display: none;
    max-width: 600px;
    line-height: 1.5;
  }
  @include respond(lge) {
    font-size: 1.5rem;
  }

}
body.page .intro {
  display: none;
}

// LAYOUT FOR BODY.PAGE 

body.page {
  .wrapper {
    display: flex;
    align-items: stretch;
  }
}

body.page {
  .hero {
    width: 100%;

    @include respond(med) {
      display: grid;
      grid-template-columns: 30% 70%;
    }
  }
  .header {
    @include respond(sml) {
      width: 100%;
      // height: 100%;
    }
  }
  .hero-right {
    @include respond(sml) {
      width: 100%;
      // float: right;
    }
    @include respond(med) {
      width: 100%;
      padding-left: 50px;
      padding-top: 140px;
    }
    @include respond(lge) {
      padding-left: 80px;
    }
  }
}
/*****************************************************************************/
/*
/* Posts
/*
/*****************************************************************************/

.content-wrapper {
  h1 {
    margin-top: 0;
  }
}

body.program {
  h2 {
    color: $cobras-blue;
    // border-bottom: 1px solid $cobras-red;
    margin-top: 30px;
  }
  h3 {
    border-top: 1px solid $cobras-red;
    margin-top: 40px;
    padding-top: 20px;
  }
  // p, li {
  //   font-size: 1.1em;
  //   line-height: 1.25;
  // }
  li {
    margin: 0.5em 0;
  }
}
p, li {
  font-size: 1.1rem;
  line-height: 1.25;
}

//  responsive youtube embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-content a {
  color: $cobras-red;
  font-weight: 700;
  text-decoration: none;
  transition: all ease 300ms;

  &:hover {
    text-decoration: underline;
  }
}
.sponsors {
  border-top: 1px solid $cobras-blue;
  // border-radius: 5px;
  // background: #fff;
  padding-top: 20px;
  margin-top: 100px;

  h3 {
    margin-top: 0;
    font-weight: 300;
    color: $cobras-blue;
    text-transform: uppercase;
    font-size: 1rem;

    span {
      font-weight: 700;
      font-size: 1rem;
    }
  }
  .website-by p {
    font-size: 0.8rem;
  }
}
body.program .sponsors h3 {

  border-top: none;
  margin-top: 0;


}
.sponsor-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  img {
    filter: grayscale(100%);
    transition: all ease 300ms;
    width: 80px;
    margin: 10px;

    &:hover {
      filter: grayscale(0);
    }
  }
}

.website-by {
  // text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.6em;
  color: grey;
  margin: 30px 0;

  a {
    color: grey;
    transition: all 300ms ease;

    &:hover {
      color: $cobras-red;
    }
  }
}
.header {
  // position: absolute;
  // width: 100%;
  z-index: 1;
  background: $cobras-red;
  background: $gradient;
  padding: 25px;
  position: relative;
}
.headerbar {
  @include respond(med) {
    max-width: 600px;
  }
}
.headerbar .link-to-home {
  width: 100%;
  margin-bottom: 15px;
  max-width: 195px;
}
.headerbar img {
  // width: auto;
  max-height: 40px;
}

body.page {
  .headerbar img {
    max-width: 100%;
  }

  .site-title {
    @include respond(sml) {
      font-size: 2.5rem;
      line-height: 2rem;
    }
    @include respond(med) {
      font-size: 2.5rem;
      margin-top: 70px;
      line-height: 2.6rem;
    }
    @include respond(lge) {
      font-size: 3rem;
      margin-top: 70px;
      line-height: 3rem;
    }
  }

}

.site-title {
  font-weight: 900;
  font-family: $type-headline;
  display: block;
  font-size: 2.3rem;
  color: #fff;
  margin-top: 20px;

  @include respond(sml) {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @include respond(med) {
    font-size: 4rem;
    line-height: 3.5rem;
    margin-top: 100px;
  }
}

.link-to-home-icon {
  display: block;
  position: absolute;
  top: 30px;
  right: 25px;
  height: 30px;

    svg {
      fill: rgba(#fff, 0.4);
      height: 100%;
      width: auto;
    }

}
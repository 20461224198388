.tiles-container {
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
  grid-template-columns: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    // align-self: stretch;
    display: grid;
  }

  @include respond(sml) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 150px;

  }
  @include respond(med) {
    grid-template-rows: 150px 150px;

  }
}
.page-nav .tiles-container li {
  margin: 0;
}
.tile-item {
  // width: 100%;
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px 0 rgba(127,127,127,0.50);
  align-items: center;
  position: relative;
  border-left: 8px solid $cobras-blue;
  transition: all 300ms ease;
  // align-self: center;
  color: $cobras-blue;
  text-decoration: none;
  list-style: none;
  text-align: center;
  font-weight: 700;
  font-family: $type-headline;

  grid-template-columns: 100%;

  @include respond(sml) {
    border-left: none;
    border-bottom: 8px solid $cobras-blue;
    font-size: 1.75rem;
  }
  @include respond(med) {
    font-size: 1.3rem;
  }
  @include respond(lge) {
    font-size: 1.8rem;
  }
}
.tile-item:hover {
  transform:scale(1.02,1.02);
  border-color: $cobras-red;
  // background: desaturate($cobras-red, 90%); 
  color: $cobras-red;
}


// styles for body.page 
body.page {
  .tiles-container {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0 0;
  
    @include respond(sml) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    @include respond(med) {
      grid-template-columns: 1fr;
      // grid-template-rows: 150px 150px;
      row-gap: 20px;
    }
  }
  .tile-item {
    margin: 0;
    border-radius: 5px;
    background-color:transparent;
    transition: all 300ms ease;
    // align-self: center;
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    box-shadow: none;
    padding: 10px;
    font-weight: 300;
    font-size: 1rem;
    color: #fff;
    font-family: $type-headline;

    &:hover {
      background: #fff;
      color: $cobras-blue;
      transform: none;
    }
   
    grid-template-columns: 100%;
  
    @include respond(sml) {
      // padding: 0;
    }
    @include respond(med) {
      font-size: 1.1rem;
      padding: 20px;
    }
    @include respond(lge) {
      font-size: 1.2rem;
    }
  }
}

.page-nav {
  @include respond(med) {
    max-width: 350px;
  }
}